import React from "react";
import { func, any } from "prop-types";
import { get, map, sortBy } from "lodash";
import { Scoped } from "kremling";
import { Link } from "react-router-dom";
import AsyncDecorator from "async-decorator/rx6";
import { createDisposableModal } from "react-disposable-modal";

import auth from "cp-client-auth!sofe";
import { CpLoader, CpButton } from "canopy-styleguide!sofe";

import { getVersions, createVersion } from "src/versions2/versions.resource.js";
import css from "src/versions2/versions-styles.krem.css";
import EditVersion from "src/versions/edit-version.component.js";

@AsyncDecorator
export default class VersionManager extends React.Component {
  static propTypes = {
    cancelWhenUnmounted: func,
    match: any,
  };

  state = { loading: true, versions: [], loggedInUser: null };

  componentDidMount() {
    this.props.cancelWhenUnmounted(
      getVersions().subscribe((versions) => {
        this.setState({ versions, loading: false });
      })
    );

    this.props.cancelWhenUnmounted(
      auth
        .getLoggedInUserAsObservable()
        .subscribe((loggedInUser) => this.setState({ loggedInUser }))
    );
  }

  render() {
    const { versions, loading } = this.state;

    const hasAccess = this.state.loggedInUser
      ? auth.hasAccess(this.state.loggedInUser)
      : () => false;

    return (
      <Scoped css={css}>
        <div className="cps-nav-content +tall-top +full">
          <div className="list-header-row">
            <div className="list-header-title">Version Manager</div>
            {hasAccess("sme_publish") && (
              <CpButton
                btnType="flat"
                onClick={this.createNewVersion.bind(
                  null,
                  this.handleCreateNewVersion
                )}
              >
                New Version
              </CpButton>
            )}
          </div>
          {loading && <CpLoader />}
          {!loading && !versions.length ? (
            <div className="cps-padding-24">
              <div className="cps-text-center">
                <img src="https://cdn.canopytax.com/images/empty-state-files.svg" />
              </div>
              <div className="cps-text-muted cps-margin-top-12 cps-text-center">
                <div className="cps-light-gray">
                  {`You do not have any versions...`}
                </div>
              </div>
            </div>
          ) : (
            <div>
              {map(versions, (version) => (
                <div className="list-row" key={version.id}>
                  <Link to={`/sme/versions/${version.id}?manage=1`}>
                    {version.name}
                  </Link>
                </div>
              ))}
            </div>
          )}
        </div>
      </Scoped>
    );
  }

  handleCreateNewVersion = (...args) => {
    const { name, description, year, product, copiedFrom } = args[0];

    this.props.cancelWhenUnmounted(
      createVersion(name, description, year, product, copiedFrom).subscribe(
        (version) => {
          this.setState((prevState) => {
            prevState.versions.push(version);
            prevState.versions = sortBy(prevState.versions, ["id"]);
            return { ...prevState };
          });
        }
      )
    );
  };

  createNewVersion = (createVersion) => {
    createDisposableModal(EditVersion, {}).subscribe(createVersion);
  };
}
